import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
  experimentalForceLongPolling: true, // this line
  useFetchStreams: false, // and this line
};

firebase.initializeApp(config);
firebase.storage();
// If we are running locally, use the local functions emulator
if (process.env.REACT_APP_LOCAL) {
  console.log('Using local functions emulator');
  firebase.functions().useEmulator('localhost', 3000);
}

export default firebase;
