import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Customers from 'pages/Customers';
import Revenue from 'pages/Revenue';
import Invoices from 'pages/Invoices';
import Customer from 'pages/Customer';
import Testing from 'pages/Testing';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => {
  const { group } = useSelector(
    (state) => ({
      group: state.auth.userData.group,
    }),
    shallowEqual
  );

  /** Determine the user's default homepage */
  let defaultPath = paths.LOGIN;
  switch (group) {
    case 'admin':
      defaultPath = paths.USERS;
      break;
    case 'reseller':
      defaultPath = paths.REVENUE;
      break;
    case 'property':
      defaultPath = paths.CUSTOMERS;
      break;
    case 'distributor':
      defaultPath = paths.REVENUE;
      break;
    default:
      break;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.IMPORT_USERS} component={User} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />
        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.ADD_CUSTOMERS} component={Customer} />
        <PrivateRoute path={paths.IMPORT_CUSTOMERS} component={Customer} />
        <PrivateRoute path={paths.MODIFY_CUSTOMER} component={Customer} />
        <PrivateRoute path={paths.REVENUE} component={Revenue} />
        <PrivateRoute path={paths.CUSTOMERS} component={Customers} />
        <PrivateRoute path={paths.INVOICES} component={Invoices} />
        <PrivateRoute path={paths.TESTING} component={Testing} />
        <Route path={paths.ROOT}>
          {/* Redirect each user to their default path if they go to the homepage */}
          {defaultPath && <Redirect to={defaultPath} />}
        </Route>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
