import moment from 'moment-timezone';

/** Provides formatting utils */
export const Format = {
  /**
   * Formats date as "Oct 31, 2018"
   * @param date - The date object to format
   */
  formatDate: (date) =>
    moment(date).tz('America/New_York').format('MMM D, YYYY'),

  /**
   * Formats number as "100,000.02"
   * @param value - The number to format
   */
  formatNumber: (value) => {
    if (!value) return 0;

    return value.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  },
};
