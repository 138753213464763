import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { toastr } from 'react-redux-toastr';

import UserForm from 'components/UserForm';
import { createUser, modifyUser, fetchUsers } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import firebase from 'firebase.js';
import { createDocument } from '../../state/api';

/** How to handle email login links during bulk creation */
const actionCodeSettings = {
  url: process.env.REACT_APP_LOGIN_PAGE_URL,
  handleCodeInApp: true,
};

const User = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);
  const location = useLocation();
  // const isImport = location.pathname.includes('import');

  const [importSuccess, setImportSuccess] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const { success, userData, error, currentUserId } = useSelector(
    (state) => ({
      success: state.users.success,
      userData: state.users.data.find((user) => user.id === id),
      error: state.users.error,
      currentUserId: state.auth.userData.id,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!userData) {
        dispatch(fetchUsers(id));
      }
    }
  }, [isEditing, id, userData, dispatch]);

  const redirect = ((isEditing && error) || success || importSuccess) && (
    <Redirect to={paths.USERS} />
  );

  const editUserMessage = useFormatMessage('User.editUser');

  const onSubmitHandler = (value) => {
    // if (isImport) {
    //   /** Submit the CSV and create all the users */
    //   const onSubmitImports = async () => {
    //     setImportLoading(true);

    //     try {
    //       const createUserAuth = firebase
    //         .functions()
    //         .httpsCallable('httpsUserCreate');

    //       /** List of tasks that must be performed for each property */
    //       const propertyPromises = value.imports.map(async (property) => {
    //         // Create the user in our Auth DB (for logging in)
    //         const {
    //           data: { uid: propUid },
    //         } = await createUserAuth({
    //           email: property.email,
    //           group: 'property',
    //         });

    //         // Create the user in our firestore DB (for meta data)
    //         await createDocument('users', propUid, {
    //           resellerId: property.resellerId || null,
    //           group: 'property',
    //           createdAt: new Date().toDateString(),
    //           ...property,
    //         });

    //         // Send out emails to all properties so they can login
    //         await firebase
    //           .auth()
    //           .sendSignInLinkToEmail(property.email, actionCodeSettings);
    //         console.log('Sent email', property.email, actionCodeSettings);
    //       });

    //       await Promise.all(propertyPromises);

    //       setImportSuccess(true);
    //       toastr.success('', 'Import completed successfully.');
    //     } catch (err) {
    //       console.error(err);
    //       toastr.error('', 'There was a problem importing the properties');
    //     }

    //     setImportLoading(false);
    //   };

    //   onSubmitImports();
    // } else {
    const newUser = {
      ...value,
      isEditing,
      id,
      updatedAt: new Date().getTime(),
      updatedBy: currentUserId,
    };

    console.log('Submitting User:', newUser);

    if (isEditing) {
      dispatch(modifyUser(newUser));
    } else {
      dispatch(
        createUser({ ...newUser, createdAt: new Date().toDateString() })
      );
    }
    // }
  };

  return (
    <>
      {redirect}
      <section className="hero is-small">
        <div className="hero-body">
          <h1 className="title">
            {/* {isImport
              ? 'Import Properties'
              : `${isEditing ? editUserMessage : 'New User'}`} */}
            {isEditing ? editUserMessage : 'New User'}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !userData ? (
          <ClipLoader />
        ) : (
          <UserForm
            isEditing={isEditing}
            // isImport={isImport}
            importLoading={importLoading}
            user={
              isEditing
                ? userData
                : {
                    name: '',
                    email: '',
                    address: '',
                    createdAt: new Date().toDateString(),
                    group: '',
                    resellerId: '',
                    quickbooksId: null,
                  }
            }
            onSubmitHandler={onSubmitHandler}
          />
        )}
      </section>
    </>
  );
};

export default User;
