import React from 'react';

const Card = ({ pad, ...props }) => {
  const { style, ...otherProps } = props;
  return (
    <div
      className="standard-radius"
      style={{
        backgroundColor: 'white',
        boxShadow: '0px 0px 35px 0px rgba(154, 161, 171, 0.15)',
        display: 'inline-block',
        ...(pad && {
          padding: '1.5rem',
        }),
        ...style,
      }}
      {...otherProps}
    />
  );
};

export default Card;
