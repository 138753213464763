import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { availableLocales, flags } from 'utils';
import { setUserLocale } from 'state/actions/preferences';
import { useFormatMessage } from 'hooks';
import { logout } from 'state/actions/auth';
import paths from 'pages/Router/paths';
import defaultLogo from 'assets/user-default-log.svg';
import Link from '../Link';

const NavBar = ({ handleMobileToggle, asideMobileActive }) => {
  const [navMobileActive, setNavMobileActive] = useState(false);

  const { userName, logoUrl, locale } = useSelector(
    (state) => ({
      userName: state.auth.userData.name,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  const changeLocaleHandler = (local) => {
    dispatch(setUserLocale(local));
  };

  const locales = availableLocales.filter((local) => local !== locale);

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a
          className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
          onClick={handleMobileToggle}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                'mdi-24px',
                { 'mdi-forwardburger': !asideMobileActive },
                { 'mdi-backburger': asideMobileActive }
              )}
            />
          </span>
        </a>
        <div
          className="is-hidden-mobile is-hidden-tablet-only"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '.75rem',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
        >
          Distributor Dashboard
        </div>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
          onClick={onMobileToggleHandler}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-dots-vertical': !navMobileActive },
                { 'mdi-close': navMobileActive }
              )}
            />
          </span>
        </a>
      </div>
      <div
        className={classNames('navbar-menu', 'fadeIn', 'animated', 'faster', {
          'is-active': navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div
                className="is-user-avatar"
                style={{
                  alignItems: 'center',
                }}
              >
                <i
                  className="mdi mdi-help-circle"
                  style={{
                    fontSize: '2rem',
                    color: '#8a8c8d',
                  }}
                />
              </div>
              <div>
                <span>Help</span>
              </div>
            </a>
            <div className="navbar-dropdown" style={{ width: 200 }}>
              <a
                className="navbar-item"
                id="email"
                target="_blank"
                rel="noreferrer"
                href="mailto:dashboards@freecast.com"
                onClick={onMobileToggleHandler}
              >
                <span className="icon">
                  <i className="mdi mdi-email" />
                </span>
                <span>Email support</span>
              </a>
              <hr className="navbar-divider" />
              <div
                className="navbar-item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 'medium',
                  }}
                >
                  Call support
                </span>
                <span style={{ fontStyle: 'italic' }}>10am-5pm EST</span>
                <span style={{ marginTop: '.5rem' }}>Technical questions:</span>
                <a href="tel:+14072782847">407-278-2847</a>
                <span style={{ marginTop: '.5rem' }}>Billing questions:</span>
                <a href="tel:+14073741666">407-374-1666</a>
              </div>
            </div>
          </div>
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar" style={{ alignItems: 'center' }}>
                <i
                  className="mdi mdi-account"
                  style={{ fontSize: '2rem', color: '#8a8c8d' }}
                />
              </div>
              <div className="is-user-name">
                <span>{userName}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="navbar-dropdown">
              <Link to={paths.PROFILE} onClick={onMobileToggleHandler}>
                <span className="icon">
                  <i className="mdi mdi-account" />
                </span>
                <span>{useFormatMessage('NavBar.profile')}</span>
              </Link>
              <hr className="navbar-divider" />
              <a
                className="navbar-item"
                id="logout"
                onClick={onClickLogoutHandler}
              >
                <span className="icon">
                  <i className="mdi mdi-logout" />
                </span>
                <span>{useFormatMessage('NavBar.logOut')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
  asideMobileActive: PropTypes.bool,
};

export default NavBar;
