/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm, useWatch, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useFormatMessage } from 'hooks';
import ErrorMessage from 'components/ErrorMessage';
// import { v4 as uuidv4 } from 'uuid';
// import { CSVReader } from 'react-papaparse';
import * as yup from 'yup';
import * as changeCase from 'change-case';

import './UserForm.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  name: yup.string().required('Please enter a name'),
  address: yup.string().notRequired(),
  city: yup.string().notRequired(),
  state: yup.string().notRequired(),
  zipcode: yup.string().notRequired(),
  createdAt: yup.string().notRequired(),
  billingEmail: yup
    .string()
    .email()
    .when('group', (group) => {
      if (group === 'property')
        return yup.string().email().required('Please enter email address');
      return yup.string().notRequired();
    }),
  resellerId: yup.string().notRequired(),
  distributorId: yup
    .string()
    .email()
    .when('group', (group) => {
      if (group === 'property' || group === 'distributor')
        return yup.string().required('Please select a distributor');
      return yup.string().notRequired();
    }),
  quickbooksId: yup.string().notRequired(),
  pricePerDoor: yup.number().notRequired(),
  totalUnits: yup.number().notRequired(),
  minimumUnits: yup.number().notRequired(),
  revenueSplitAdvertising: yup.number().notRequired(),
  revenueSplitSubscription: yup.number().notRequired(),
  revenueSplitAffiliate: yup.number().notRequired(),
  onlyBillActiveCustomers: yup.boolean().notRequired(),
  group: yup
    .string()
    .required('Please select a group')
    .test('group', (value) => {
      switch (value.toLowerCase()) {
        case 'admin':
          return true;
        case 'reseller':
          return true;
        case 'property':
          return true;
        case 'distributor':
          return true;
        default:
          return false;
      }
    }),
});

const propertySchema = yup.object().shape({
  email: yup.string().email().required().meta({ noSelfEdit: true }),
  billingEmail: yup.string().required(),
  name: yup.string().required(),
  address: yup.string().notRequired().nullable(),
  city: yup.string().notRequired().nullable(),
  state: yup.string().notRequired().nullable(),
  zipcode: yup.string().notRequired().nullable(),
  resellerId: yup.string().notRequired().meta({ noSelfEdit: true }).nullable(),
  pricePerDoor: yup.number().required().meta({ noSelfEdit: true }),
  totalUnits: yup.number().required().meta({ noSelfEdit: true }),
  minimumUnits: yup.number().required().meta({ noSelfEdit: true }),
  onlyBillActiveCustomers: yup.boolean().required().meta({ noSelfView: true }),
});

// Properties get all fields except for the ones we specify in the filter
const propertyFormFields = Object.entries(propertySchema.describe().fields)
  .filter(([key]) => key !== 'email' && key !== 'name' && key !== 'resellerId')
  .map(([key, value]) => ({
    name: key,
    ...value,
  }));

// const bulkImportSchema = yup.object().shape({
//   imports: yup.array().of(propertySchema).required(),
// });

const UserForm = ({
  isEditing,
  isProfile,
  // isImport,
  // importLoading,
  user,
  onSubmitHandler,
}) => {
  const { loading, success, group } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
      group: state.auth.userData.group,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, watch, setValue, setError } = useForm(
    {
      defaultValues: { ...user },
      // resolver: yupResolver(isImport ? bulkImportSchema : schema),
      resolver: yupResolver(schema),
    }
  );

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(usersCleanUp());
  }, [dispatch, success, setValue]);

  // useEffect(() => {
  //   if (isImport) register('imports');
  // }, []);

  // const handleOnDrop = (data) => {
  //   console.log('---------------------------');

  //   let dropError;
  //   for (let i = 0; i < data.length; i += 1) {
  //     console.log(data[i]);
  //     if (data[i].errors.length) {
  //       dropError = `Row ${i + 1}: ${data[i].errors[0].message}`;
  //     }

  //     try {
  //       importSchema.validateSync(data[i].data);
  //     } catch (err) {
  //       dropError = `Row ${i + 1}: ${err.errors[0]}`;
  //     }
  //   }

  //   if (dropError) {
  //     setError('imports', {
  //       message: dropError,
  //     });
  //     return;
  //   }

  //   const properties = data.map((csvRow) => ({
  //     ...csvRow.data,
  //   }));
  //   console.log(properties);

  //   setValue('imports', properties, { shouldValidate: true });
  //   console.log('---------------------------');
  // };

  // const handleOnError = (err, file, inputElem, reason) => {
  //   console.log(err);
  // };

  // const handleOnRemoveFile = (data) => {
  //   console.log('---------------------------');
  //   console.log(data);
  //   setValue('imports', undefined, { shouldValidate: true });
  //   console.log('---------------------------');
  // };

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  const goBackMessage = useFormatMessage('UserForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('UserForm.pickFile');

  const emailMessage = useFormatMessage('UserForm.email');

  const adminMessage = useFormatMessage('UserForm.admin');

  // if (isImport) {
  //   return (
  //     <form onSubmit={handleSubmit(onSubmitHandler)}>
  //       <a
  //         href="https://docs.google.com/spreadsheets/d/1i_nOo0VzzsTZtxlrr9-YV9o0-VupFLVOEHErHBMLTOQ/edit?usp=sharing"
  //         rel="noreferrer"
  //         target="_blank"
  //         style={{ marginBottom: '20px', display: 'block' }}
  //       >
  //         View Example File
  //       </a>
  //       <div style={{ marginBottom: 16 }}>
  //         <CSVReader
  //           onDrop={handleOnDrop}
  //           onError={handleOnError}
  //           config={{
  //             header: true,
  //             dynamicTyping: true,
  //           }}
  //           addRemoveButton
  //           onRemoveFile={handleOnRemoveFile}
  //         >
  //           <span>Drop CSV file here or click to upload.</span>
  //         </CSVReader>
  //       </div>
  //       {errors.imports && <ErrorMessage text={errors.imports.message} />}
  //       <div style={{ marginTop: 16 }} className="field">
  //         <div className="field is-grouped">
  //           <div className="control">
  //             <button
  //               type="submit"
  //               disabled={!watch('imports')}
  //               className={`button is-primary ${importLoading && 'is-loading'}`}
  //             >
  //               <span>{useFormatMessage('UserForm.submit')}</span>
  //             </button>
  //           </div>
  //           <Link to={paths.CUSTOMERS} className="button">
  //             Go back
  //           </Link>
  //         </div>
  //       </div>
  //     </form>
  //   );
  // }

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent" style={{ flex: 2 }}>
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                {useFormatMessage('UserForm.userInfo')}
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {isEditing && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">ID</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="id"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{emailMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="email"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.email,
                              })}
                              ref={register}
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={invalidEmailMessage} />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{adminMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="group"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field has-check is-horizontal">
                      <div className="field-label">
                        <label className="label">{adminMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="field">
                            <div className="control">
                              <div
                                className={classNames(`select`, {
                                  'is-danger': errors.group,
                                })}
                              >
                                <select
                                  type="group"
                                  name="group"
                                  ref={register}
                                >
                                  <option value="admin">Admin</option>
                                  <option value="distributor">
                                    Distributor
                                  </option>
                                  <option value="reseller">Reseller</option>
                                  <option value="property">Property</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.group && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* The distributor id (shared between properties and distributors) */}
                {(watch('group') === 'distributor' ||
                  watch('group') === 'property') && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Distributor Id</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly={
                                isEditing === true ? 'readOnly' : undefined
                              }
                              className={classNames(
                                `input ${
                                  isEditing === true ? 'is-static' : ''
                                }`,
                                {
                                  'is-danger': errors.distributorId,
                                }
                              )}
                              type="string"
                              ref={register}
                              name="distributorId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.distributorId && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* Distributor specific fields */}
                {watch('group') === 'distributor' && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Reseller Id</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly={
                                group !== 'admin' ? 'readOnly' : undefined
                              }
                              className={classNames(
                                `input ${group !== 'admin' ? 'is-static' : ''}`,
                                {
                                  'is-danger': errors.resellerId,
                                }
                              )}
                              type="string"
                              ref={register}
                              name="resellerId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.resellerId && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* Revenue split fields (for distributors and resellers) */}
                {(watch('group') === 'distributor' ||
                  watch('group') === 'reseller') && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Rev Split % Advertising</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly={
                                group !== 'admin' ? 'readOnly' : undefined
                              }
                              className={classNames(
                                `input ${group !== 'admin' ? 'is-static' : ''}`,
                                {
                                  'is-danger': errors.revenueSplitAdvertising,
                                }
                              )}
                              type="number"
                              min="1"
                              max="100"
                              ref={register}
                              name="revenueSplitAdvertising"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.revenueSplitAdvertising && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Rev Split % Affiliate</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly={
                                group !== 'admin' ? 'readOnly' : undefined
                              }
                              className={classNames(
                                `input ${group !== 'admin' ? 'is-static' : ''}`,
                                {
                                  'is-danger': errors.revenueSplitAffiliate,
                                }
                              )}
                              type="number"
                              min="1"
                              max="100"
                              ref={register}
                              name="revenueSplitAffiliate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.revenueSplitAffiliate && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Rev Split % Subscription
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              readOnly={
                                group !== 'admin' ? 'readOnly' : undefined
                              }
                              className={classNames(
                                `input ${group !== 'admin' ? 'is-static' : ''}`,
                                {
                                  'is-danger': errors.revenueSplitSubscription,
                                }
                              )}
                              type="number"
                              min="1"
                              max="100"
                              ref={register}
                              name="revenueSplitSubscription"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.revenueSplitSubscription && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {/* Property specific fields */}
                {watch('group') === 'property' && (
                  <>
                    {/** Auto generate all the property fields */}
                    {propertyFormFields.map((formField) => {
                      // Properties should not be able to view noSelfView fields
                      const noView =
                        group === 'admin' ? false : formField?.meta?.noSelfView;
                      if (noView)
                        return <React.Fragment key={formField.name} />;

                      // Admins can edit
                      const noEdit =
                        group === 'admin' ? false : formField?.meta?.noSelfEdit;

                      return (
                        // eslint-disable-next-line react/jsx-fragments
                        <React.Fragment key={formField.name}>
                          <div className="field is-horizontal">
                            <div className="field-label is-normal">
                              <label className="label">
                                {changeCase.capitalCase(formField.name)}
                              </label>
                            </div>
                            <div className="field-body">
                              <div className="field">
                                <div className="control">
                                  <input
                                    readOnly={noEdit ? 'readOnly' : undefined}
                                    className={classNames(
                                      `${
                                        formField.type !== 'boolean'
                                          ? 'input'
                                          : ''
                                      } ${noEdit ? 'is-static' : ''}`,
                                      {
                                        'is-danger': errors[formField.name],
                                      }
                                    )}
                                    type={
                                      formField.type === 'boolean'
                                        ? 'checkbox'
                                        : formField.type
                                    }
                                    ref={register}
                                    name={formField.name}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors[formField.name] && (
                            <div className="field is-horizontal">
                              <div className="field-label is-normal" />
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}

                {isEditing && user.group === 'property' && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Quickbooks ID</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="quickbooksId"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isEditing && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('UserForm.created')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="createdAt"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button is-primary ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile && (
                          <Link to={paths.USERS} className="button">
                            {goBackMessage}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
