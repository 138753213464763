export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  IMPORT_USERS: '/users/import',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  CUSTOMERS: '/customers',
  REVENUE: '/revenue',
  TESTING: '/testing',
  ADD_CUSTOMERS: '/customers/new',
  IMPORT_CUSTOMERS: '/customers/import',
  MODIFY_CUSTOMER: '/customers/:id',
  INVOICES: '/invoices',
};
