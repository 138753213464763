/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  Redirect,
  Link,
} from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';
import { CSVReader } from 'react-papaparse';
import { useDocument } from '@nandorojo/swr-firestore';
import ErrorMessage from 'components/ErrorMessage';
import firebase from 'firebase.js';
import { toastr } from 'react-redux-toastr';

import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';

import paths from 'pages/Router/paths';

const Customer = () => {
  const { group, uid } = useSelector(
    (state) => ({
      group: state.auth.userData.group,
      uid: state.auth.userData.id,
    }),
    shallowEqual
  );

  const history = useHistory();

  const schema = yup.object().shape({
    propertyId:
      group === 'property'
        ? yup.string().notRequired()
        : yup.string().required('Please enter the property ID'),
    name: yup.string().required('Please enter a valid name'),
    email: yup.string().email().required('Please enter a valid email address'),
    address: yup.string().notRequired('Please enter an address'),
    unit: yup.string().notRequired('Please enter a unit'),
    registrationCode: yup.string().notRequired(),
  });

  const importSchema = yup.object().shape({
    propertyId:
      group === 'property'
        ? yup.string().notRequired().nullable()
        : yup.string().required('Please enter the property ID'),
    name: yup.string().required('Please enter a valid name'),
    email: yup.string().email().required('Please enter a valid email address'),
    address: yup.string().notRequired('Please enter an address').nullable(),
    unit: yup.string().notRequired('Please enter a unit').nullable(),
  });

  const bulkImportSchema = yup.object().shape({
    imports: yup.array().of(importSchema).required(),
  });

  const { id } = useParams();
  const location = useLocation();
  const isImport = location.pathname.includes('import');
  const isEditing = useMemo(() => !!id, [id]);
  const [createLoading, setCreateLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  console.log('Loading using id', id);
  const { data: customerData, loading, error, update } = useDocument(
    isEditing ? `customers/${id}` : null,
    {
      listen: true,
      onError: (err) => {
        console.log(err);
      },
    }
  );
  console.log('Retrieved customer data', customerData);

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    reset,
    control,
    watch,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      ...customerData,
      ...(group === 'property' && {
        propertyId: uid,
      }),
    },
    resolver: yupResolver(isImport ? bulkImportSchema : schema),
  });

  useEffect(() => {
    if (customerData) {
      // If this user does not belong to the property, navigate them away
      if (group !== 'admin' && customerData.propertyId !== uid) {
        console.error('You do not have permission to access this customer');
        history.push('/customers');
      }
      reset(customerData);
    }
  }, [customerData]);

  useEffect(() => {
    if (isImport) register('imports');
  }, [isImport]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.CUSTOMERS} />
  );

  const onSubmitHandler = async (value) => {
    setCreateLoading(true);

    /** Submit the CSV and create all the customers */
    if (isImport) {
      try {
        const createCustomerFromImport = firebase
          .functions()
          .httpsCallable('httpsCustomerCreate');

        /** List of tasks that must be performed for each customer */
        const customerPromises = value.imports.map(async (customer) => {
          // Create the user in our firestore DB (for meta data)
          await createCustomerFromImport({
            ...customer,
            ...(group === 'property' && {
              propertyId: uid,
            }),
          });
        });

        await Promise.all(customerPromises);

        setSuccess(true);
        toastr.success('Import was completed successfully');
      } catch (err) {
        console.error(err);
        toastr.error(
          '',
          'There was a problem importing the customers. Please contact us for support.'
        );
      }
    } else {
      const newCustomer = {
        ...value,
      };

      if (isEditing) {
        try {
          await update(newCustomer);
          setSuccess(true);
          toastr.success('Customer was updated successfully');
        } catch (err) {
          console.error(err);
          toastr.error(
            '',
            'There was a problem updating the customer. Please contact us for support.'
          );
        }
      } else {
        try {
          const createCustomerAndSendCode = firebase
            .functions()
            .httpsCallable('httpsCustomerCreate');

          await createCustomerAndSendCode({
            ...newCustomer,
            ...(group === 'property' && {
              propertyId: uid,
            }),
          });

          setSuccess(true);
          toastr.success('Customer was created successfully');
        } catch (err) {
          console.error(err);
          toastr.error(
            '',
            'There was a problem creating the customer. Please contact us for support.'
          );
        }
      }
    }

    setCreateLoading(false);
  };

  const handleOnDrop = (data) => {
    console.log('---------------------------');
    console.log(data);

    let dropError;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].errors.length) {
        dropError = `Row ${i + 1}: ${data[i].errors[0].message}`;
      }
      try {
        importSchema.validateSync(data[i].data);
      } catch (err) {
        dropError = `Row ${i + 1}: ${err.errors[0]}`;
      }
    }

    if (dropError) {
      setError('imports', {
        message: dropError,
      });
      return;
    }

    const customers = data.map((csvRow) => ({
      ...csvRow.data,
    }));
    console.log(customers);

    setValue('imports', customers, { shouldValidate: true });
    console.log('---------------------------');
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    setValue('imports', undefined, { shouldValidate: true });
    console.log('---------------------------');
  };

  return (
    <>
      {redirect}
      <section className="hero is-small">
        <div className="hero-body">
          <h1 className="title">
            {isEditing
              ? 'Edit Customer'
              : `${isImport ? 'Import Customers' : 'Add Customer'} `}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !customerData ? (
          <ClipLoader />
        ) : (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {isImport ? (
              <>
                <a
                  href="https://docs.google.com/spreadsheets/d/1OlC6R1TbRJ7woXuh6temWiYo8-Rv06iv4L45MM9UFOg/edit?usp=sharing"
                  rel="noreferrer"
                  target="_blank"
                  style={{ marginBottom: '20px', display: 'block' }}
                >
                  View Example File
                </a>
                <div style={{ marginBottom: 16 }}>
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    config={{
                      header: true,
                      dynamicTyping: true,
                    }}
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                  >
                    <span>Drop CSV file here or click to upload.</span>
                  </CSVReader>
                </div>
                {errors.imports && (
                  <ErrorMessage text={errors.imports.message} />
                )}
                <div style={{ marginTop: 16 }} className="field">
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        type="submit"
                        disabled={!watch('imports')}
                        className={`button is-primary ${
                          ((loading && isEditing) || createLoading) &&
                          'is-loading'
                        }`}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                    <Link to={paths.CUSTOMERS} className="button">
                      Go back
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <div className="tile is-ancestor">
                <div className="tile is-parent" style={{ flex: 2 }}>
                  <div className="card tile is-child">
                    <header className="card-header">
                      <p className="card-header-title">
                        <span className="icon">
                          <i className="mdi mdi-account-edit default" />
                        </span>
                        Customer
                      </p>
                    </header>
                    <div className="card-content">
                      {group === 'admin' && (
                        <>
                          <div className="field is-horizontal">
                            <div className="field-label is-normal">
                              <label className="label">Property ID</label>
                            </div>
                            <div className="field-body">
                              <div className="field">
                                <div className="control">
                                  <input
                                    type="text"
                                    className={classNames(`input`, {
                                      'is-danger': errors.propertyId,
                                    })}
                                    name="propertyId"
                                    ref={register}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors.propertyId && (
                            <div className="field is-horizontal">
                              <div className="field-label is-normal" />
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {isEditing ? (
                        <div className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">Email</label>
                          </div>
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <input
                                  type="text"
                                  readOnly="readOnly"
                                  className="input is-static"
                                  name="email"
                                  ref={register}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="field is-horizontal">
                            <div className="field-label is-normal">
                              <label className="label">Email</label>
                            </div>
                            <div className="field-body">
                              <div className="field">
                                <div className="control">
                                  <input
                                    className={classNames(`input`, {
                                      'is-danger': errors.email,
                                    })}
                                    ref={register}
                                    name="email"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {errors.email && (
                            <div className="field is-horizontal">
                              <div className="field-label is-normal" />
                              <div className="field-body">
                                <ErrorMessage text="Invalid email address" />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Name</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                name="name"
                                id="name"
                                className={classNames('input', {
                                  'is-danger': errors.name,
                                })}
                                ref={register}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {errors.name && (
                        <div className="field is-horizontal">
                          <div className="field-label is-normal" />
                          <div className="field-body">
                            <ErrorMessage />
                          </div>
                        </div>
                      )}
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Address</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                ref={register}
                                name="address"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Unit</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                ref={register}
                                name="unit"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {isEditing && (
                        <div className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">Registration Code</label>
                          </div>
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <input
                                  type="text"
                                  readOnly="readOnly"
                                  className="input is-static"
                                  name="registrationCode"
                                  ref={register}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <hr />
                      <div className="field is-horizontal">
                        <div className="field-label" />
                        <div className="field-body">
                          <div className="field">
                            <div className="field is-grouped">
                              <div className="control">
                                <button
                                  type="submit"
                                  className={`button is-primary ${
                                    ((loading && isEditing) || createLoading) &&
                                    'is-loading'
                                  }`}
                                >
                                  <span>Submit</span>
                                </button>
                              </div>
                              <Link to={paths.CUSTOMERS} className="button">
                                Go back
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        )}
      </section>
    </>
  );
};

export default Customer;
