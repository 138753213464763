import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import paths from 'pages/Router/paths';
import faker from 'faker';
import { useCollection, update } from '@nandorojo/swr-firestore';
import classes from './Invoices.module.scss';

const invoiceFakeData = new Array(25).fill(null).map(() => ({
  name: faker.company.companyName(),
  price: faker.commerce.price(),
  createdAt: faker.date.recent(),
}));

const Invoices = () => {
  // const invoicesData = invoiceFakeData;
  const { group, error, loading, id } = useSelector(
    (state) => ({
      group: state.auth.userData.group,
      id: state.auth.userData.id,
      error: state.users.error,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const {
    data: invoicesData,
    error: invoicesError,
    loading: invoicesLoading,
  } = useCollection('invoices', {
    where: [
      ...(group === 'property' ? [['propertyId', '==', id]] : []),
      ...(group === 'reseller' ? [['resellerId', '==', id]] : []),
    ],
    listen: true,
  });

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const redirect = !group && <Redirect to={paths.ROOT} />;

  const columns = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Amount',
      accessor: 'totalAmount',
      Cell: ({ row }) => <span>{`$${row.original.totalAmount}`}</span>,
    },
    ...(group === 'admin'
      ? [
          {
            Header: 'Property Id',
            accessor: 'propertyId',
          },
          {
            Header: 'Property Name',
            accessor: 'propertyName',
          },
        ]
      : []),
    {
      Header: 'Billed Customer List',
      accessor: 'billedCustomerList',
      Cell: ({ row }) => (
        <>
          {row.original.billedCustomerCSV && (
            <a
              href={row.original.billedCustomerCSV}
              target="_blank"
              rel="noreferrer"
            >
              Download List
            </a>
          )}
        </>
      ),
    },
  ];

  // Update the table when the data or search changes
  useEffect(() => {
    if (!invoicesData) return;

    setData(() => {
      let newData = [...invoicesData];

      // Filter the results based on the search
      if (search) {
        newData = [
          ...newData.filter((el) => {
            return Object.values(el).some((value) => {
              if (typeof value === 'string')
                return value.toLowerCase().includes(search.toLowerCase());
              return false;
            });
          }),
        ];
      }
      console.log('Final invoice data', newData);
      return newData;
    });
  }, [search, invoicesData]);

  useEffect(() => {
    if (error || invoicesError)
      console.error('Error with customer data', error ?? invoicesError);
  }, [invoicesError, error]);

  return (
    <>
      {redirect}
      <section className="hero is-small">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Invoices</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {invoicesLoading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data}
                initialState={{
                  sortBy: [
                    {
                      id: 'createdAt',
                      desc: true,
                    },
                  ],
                }}
                resultCount={data?.length || 0}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoices;
