import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import LanguageWrapper from 'components/LanguageWrapper';
import { configureStore } from './state/store';
import './index.scss';
import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';
import './assets/css/main.css';
import { Fuego } from './feugo';
import { config } from './firebase';

const fuego = new Fuego(config);

const { store, persistor } = configureStore({});

const app = (
  <FuegoProvider fuego={fuego}>
    <Provider store={store}>
      <LanguageWrapper>
        <PersistGate persistor={persistor}>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
        </PersistGate>
      </LanguageWrapper>
    </Provider>
  </FuegoProvider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
