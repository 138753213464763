import React from 'react';
import Card from '../Card';

/** Renders a card containing statistics */
const StatisticCard = ({ label, value, icon, ...props }) => {
  return (
    <Card pad {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingLeft: '50px',
        }}
      >
        {icon && (
          <div
            className="standard-radius bg-color-primary-light hide-mobile"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '2.2rem',
              width: '2.2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <i
              className={`color-primary mdi ${icon}`}
              style={{ fontSize: '1.4rem' }}
            />
          </div>
        )}
        <span className="statistic-label">{label}</span>
        <span className="statistic-value">{value}</span>
        
      </div>
    </Card>
  );
};

export default StatisticCard;
