import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useRevenue, useFormatMessage, useFormatDate } from 'hooks';
import ClipLoader from 'react-spinners/ClipLoader';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Card from 'components/Card';
import StatisticCard from 'components/StatisticCard';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';

import { Redirect } from 'react-router-dom';
import firebase from 'firebase.js';
import { toastr } from 'react-redux-toastr';

import paths from 'pages/Router/paths';
import { Format } from 'utils/Format';
import { useCollection } from '@nandorojo/swr-firestore';
import './Revenue.css';

/*
|--------------------------------------------------------------------------
| START COMPONENTS
|--------------------------------------------------------------------------
*/
/** Renders a row for the revenue breakdown */
const RevenueRow = ({
  header,
  subheader,
  primaryLabel,
  primaryValue,
  secondaryLabel,
  secondaryValue,
}) => {
  return (
    <>
      <h3 className="section-header">{header}</h3>
      <div className="section-subheader">{subheader}</div>
      <div className="card-callout">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <span className="statistic-label" style={{ textAlign: 'center' }}>
            {primaryLabel}
          </span>
          <span className="statistic-value" style={{ textAlign: 'center' }}>
            {primaryValue}
          </span>
        </div>
        {secondaryLabel && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <span className="statistic-label" style={{ textAlign: 'center' }}>
              {secondaryLabel}
            </span>
            <span className="statistic-value" style={{ textAlign: 'center' }}>
              {secondaryValue}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
/*
|--------------------------------------------------------------------------
| START COMPONENTS
|--------------------------------------------------------------------------
*/

/** The current date */
const today = moment();
/** Yesterday's date */
const yesterday = moment(today).subtract(2, 'month').endOf('month');
/** The start of the month's date */
const startOfMonth = moment(today).subtract(2, 'month').startOf('month');

// Renders reports surrounding revenue
const Revenue = () => {
  // Retrieve user data
  const { group, error, loading, id } = useSelector(
    (state) => ({
      group: state.auth.userData.group,
      id: state.auth.userData.id,
      error: state.users.error,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  /*
  |--------------------------------------------------------------------------
  | START HELPERS
  |--------------------------------------------------------------------------
  */
  const [markModal, setMarkModal] = useState({
    statementId: null,
    isOpen: false,
  });

  const [markInProgress, setMarkInProgress] = useState({});
  /** Function that marks a statement as paid */
  const markPaid = async (statementId) => {
    try {
      // Mark row as loading
      setMarkInProgress({
        ...markInProgress,
        [statementId]: true,
      });

      const markPaidCall = firebase
        .functions()
        .httpsCallable('httpsStatementMarkPaid');

      const response = await markPaidCall({ statementId });
    } catch (err) {
      toastr.error('', `There was a problem marking the statement as paid`);
    }
  };
  /*
  |--------------------------------------------------------------------------
  | END HELPERS
  |--------------------------------------------------------------------------
  */

  /*
  |--------------------------------------------------------------------------
  | START GET REVENUE DATA
  |--------------------------------------------------------------------------
  */
  // Hook for revenue methods
  const {
    data: revenueData,
    error: revenueError,
    retrieveData: retrieveRevenueData,
    isLoading,
  } = useRevenue();
  /** Overall loading state */
  const overallLoading = loading || (isLoading && group !== 'admin');
  // When we're ready, retrieve revenue data
  useEffect(() => {
    console.log('Start fetching revenue');

    // Retrieve the revenue summary for non-admins
    if (group !== 'admin') {
      // Refresh the dashboard data
      retrieveRevenueData({
        id,
        startDate: startOfMonth.toDate().getTime(),
        endDate: yesterday.toDate().getTime(),
      });
    }
  }, [group]);
  /*
  |--------------------------------------------------------------------------
  | END GET REVENUE DATA
  |--------------------------------------------------------------------------
  */

  /*
  |--------------------------------------------------------------------------
  | START GET STATEMENTS
  |--------------------------------------------------------------------------
  */
  const {
    data: statementData,
    error: statementError,
    loading: statementsLoading,
  } = useCollection('statements', {
    where: [
      ...(group === 'distributor' || group === 'reseller'
        ? [['partnerId', '==', id]]
        : []),
    ],
    listen: true,
  });

  const [formattedStatementData, setFormattedStatementData] = useState([]);
  const [search, setSearch] = useState('');

  const columns = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {Format.formatDate(row.original.createdAt)}
        </small>
      ),
    },
    {
      Header: 'Id',
      accessor: 'id',
    },

    ...(group === 'admin'
      ? [
          {
            Header: 'Partner Name',
            accessor: 'partnerName',
          },
        ]
      : []),
    {
      Header: 'Amount',
      accessor: 'totalAmount',
      Cell: ({ row }) => <span>{`$${row.original.totalAmount}`}</span>,
    },
    {
      Header: 'PDF',
      accessor: 'pdf',
      Cell: ({ row }) => (
        <a target="_blank" rel="noreferrer" href={row.original.pdf}>
          Download PDF
        </a>
      ),
    },
    {
      Header: 'Paid',
      accessor: 'paid',
      Cell: ({ row }) => {
        // If it was marked as paid, show a green date
        if (row.original.paid) {
          return (
            <div style={{ color: 'green', fontWeight: 'bold' }}>
              {row?.original?.paidAt
                ? Format.formatDate(row.original.paidAt)
                : 'Yes'}
            </div>
          );
        }
        // Otherwise, it depends on whether they are an admin
        // Admins see a button to mark something as paid
        if (group === 'admin') {
          return (
            <a
              className={`button is-primary ${
                markInProgress?.[row.original.id] ? 'is-loading' : ''
              }`}
              onClick={() => {
                setMarkModal({
                  statementId: row.original.id,
                  isOpen: true,
                });
              }}
            >
              Mark as paid
            </a>
          );
        }
        // Others see pending
        return (
          <div style={{ color: 'orange', fontWeight: 'bold' }}>Pending</div>
        );
      },
    },
  ];

  // Update the table when the data or search changes
  useEffect(() => {
    if (!statementData) return;

    setFormattedStatementData(() => {
      let newData = [...statementData];

      // Filter the results based on the search
      if (search) {
        newData = [
          ...newData.filter((el) => {
            return Object.values(el).some((value) => {
              if (typeof value === 'string')
                return value.toLowerCase().includes(search.toLowerCase());
              return false;
            });
          }),
        ];
      }
      console.log('Final statement data', newData);
      return newData;
    });
  }, [search, statementData]);
  /*
  |--------------------------------------------------------------------------
  | END GET STATEMENTS
  |--------------------------------------------------------------------------
  */

  /** Redirect if not authed */
  const redirect = !group && <Redirect to={paths.ROOT} />;

  // Handle error
  if (statementError || revenueError || error)
    return <div className="empty-message">Error, please try again later</div>;

  // If there is no revenue data, this user does not have any distributors yet
  if (revenueData?.empty)
    return (
      <div className="empty-message">
        It looks like you do not have any distributors yet. Check back once you
        have brought in a distributor!
      </div>
    );

  return (
    <>
      {redirect}
      {markModal?.isOpen && (
        <ConfirmationModal
          isActive={markModal.isOpen}
          isLoading={loading}
          confirmButtonMessage="Mark paid"
          title="Are you sure?"
          body="Would you like to mark this invoice as paid?"
          cancelButtonMessage="Cancel"
          onConfirmation={() => {
            markPaid(markModal?.statementId);
            setMarkModal({
              statementId: null,
              isOpen: false,
            });
          }}
          onCancel={() => setMarkModal({ statementId: null, isOpen: false })}
        />
      )}
      <section className="hero is-small">
        <div className="hero-body">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center ',
            }}
          >
            <h1 className="title" style={{ marginBottom: 0 }}>
              Revenue
            </h1>
            {group !== 'admin' && (
              <DateRangePicker
                startDate={startOfMonth}
                startDateId="start"
                endDate={yesterday}
                endDateId="end"
                onDatesChange={() => null}
                onFocusChange={() => null}
                disabled
              />
            )}
          </div>
          {group !== 'admin' && (
            <p className="note">
              All revenue numbers are estimates - statements are generated once
              numbers are finalized
            </p>
          )}
        </div>
      </section>
      
      <section className="hero is-small">
      <div className="hero-body">
        <div className="section-title">Total</div>
      </div>
      </section>
      
      <section
        className="section is-main-section"
        style={{ display: 'flex', width: '100%' }}
      >
        
        {overallLoading ? (
          <ClipLoader />
        ) : (
          <div style={{ width: '100%' }}>
            {/* Overview (only for non-admins) */}
            {group !== 'admin' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: '1.5rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                    }}
                  >
                    <StatisticCard
                      label="Current Invoice Revenue"
                      value={`$${Format.formatNumber(
                        revenueData?.totalAmount
                      )}`}
                      icon="mdi-currency-usd"
                      style={{ flex: 1 }}
                    />
                    <div className="standard-gap" />
                    <StatisticCard
                      label="Active Users - Current Period"
                      value={revenueData?.totalUsers}
                      icon="mdi-account-multiple"
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>

                {/* Breakdown of revenue */}
                <Card pad style={{ width: '100%' }}>
                  <div className="section-title">Revenue Breakdown</div>

                  <RevenueRow
                    header="Ad Revenue"
                    subheader="Revenue generated whenever someone watches an ad"
                    primaryLabel="Revenue"
                    primaryValue={`$${Format.formatNumber(
                      revenueData?.advertising?.revenue
                    )}`}
                    secondaryLabel="Ads Served"
                    secondaryValue={Format.formatNumber(
                      revenueData?.advertising?.impressions
                    )}
                  />

                  <RevenueRow
                    header="Affiliate Revenue"
                    subheader="Revenue generated whenever someone purchases an affiliate
                    offering"
                    primaryLabel="Revenue"
                    primaryValue={`$${Format.formatNumber(
                      revenueData?.affiliate?.revenue
                    )}`}
                    secondaryLabel="Sales"
                    secondaryValue={Format.formatNumber(
                      revenueData?.affiliate?.conversions
                    )}
                  />

                  <RevenueRow
                    header="Subscription Revenue"
                    subheader="Revenue generated when a user upgrades their subscription"
                    primaryLabel="Revenue"
                    primaryValue={`$${Format.formatNumber(
                      revenueData?.subscription?.revenue
                    )}`}
                    secondaryLabel="Subscriptions"
                    secondaryValue={Format.formatNumber(
                      revenueData?.subscription?.subscribers
                    )}
                  />

                  {/* Only show invoices to reseller */}
                  {group === 'reseller' && (
                    <RevenueRow
                      header="MDU Invoice Revenue"
                      subheader="Revenue generated when a property is invoiced for MDU customers"
                      primaryLabel="Revenue"
                      primaryValue={`$${Format.formatNumber(
                        revenueData?.invoices?.revenue
                      )}`}
                      secondaryLabel="Invoices"
                      secondaryValue={Format.formatNumber(
                        revenueData?.invoices?.count
                      )}
                    />
                  )}
                </Card>
              </>
            )}

            {/* Statements table */}
            <Card
              pad
              style={{
                width: '100%',
                ...(group !== 'admin' && {
                  marginTop: '1.5rem',
                }),
              }}
            >
              <div className="section-title" style={{ marginBottom: '.5rem' }}>
                Revenue Statements
              </div>

              <div className="card has-table has-mobile-sort-spaced">
                <header className="card-header">
                  <p className="card-header-title">
                    <span>Search:</span>
                    <input
                      type="text"
                      className="input"
                      style={{
                        maxWidth: '250px',
                        marginLeft: '15px',
                      }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </p>
                </header>
                <div className="b-table">
                  {statementsLoading ? (
                    <ClipLoader />
                  ) : (
                    <Table
                      columns={columns}
                      data={formattedStatementData}
                      initialState={{
                        sortBy: [
                          {
                            id: 'createdAt',
                            desc: true,
                          },
                        ],
                      }}
                      resultCount={formattedStatementData?.length || 0}
                    />
                  )}
                </div>
              </div>
            </Card>
          </div>
        )}
      </section>
    </>
  );
};

export default Revenue;
