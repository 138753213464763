import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { useState, useEffect } from 'react';

/** Retrieves the revenue details for the current user */
export const useRevenue = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();

  /** Function to retrieve the revenue data */
  const retrieveData = async ({ startDate, endDate, id }) => {
    try {
      // Create a request to our firebase API
      const getRevenueRequest = firebase
        .functions()
        .httpsCallable('httpsRevenueGet');

      const result = await getRevenueRequest({
        startDate,
        endDate,
        id,
      });

      setData(result?.data);
      setIsLoading(false);
    } catch (err) {
      setError(true);
      setIsLoading(false);
      const errorMessage = firebaseError(err.message, 'en');
      toastr.error('', errorMessage);
    }
  };

  return { data, isLoading, error, retrieveData };
};
