import React, { useEffect } from 'react';
import firebase from 'firebase.js';

// Renders a list of customers
const Customers = () => {
  // Function to test invoicing
  const testInvoicing = async () => {
    try {
      // Query API
      const generateInvoices = firebase
        .functions()
        .httpsCallable('cronGenerateInvoices');
      const response = await generateInvoices();

      console.log('Response', response?.data);
    } catch (err) {
      console.error('Error', err);
    }
  };

  return (
    <>
      <section className="hero is-small">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Testing Page</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <button className="button is-primary" onClick={() => testInvoicing()}>
          <span>Test Invoicing</span>
        </button>
      </section>
    </>
  );
};

export default Customers;
