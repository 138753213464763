import React, { useEffect, useState } from 'react';
import paths from 'pages/Router/paths';
import { Link } from 'react-router-dom';
import { useCollection } from '@nandorojo/swr-firestore';
import { useSelector, shallowEqual } from 'react-redux';
import firebase from 'firebase.js';
import Table from 'components/Table';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';
import ConfirmationModal from 'components/ConfirmationModal';
import { toastr } from 'react-redux-toastr';
import classes from '../Users/Users.module.scss';

// Renders a list of customers
const Customers = () => {
  const { group, error, loading, id } = useSelector(
    (state) => ({
      group: state.auth.userData.group,
      id: state.auth.userData.id,
      error: state.users.error,
      loading: state.users.loading,
    }),
    shallowEqual
  );

  const [data, setData] = useState([]);
  const [disableLoading, setDisableLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    customerId: null,
    isOpen: false,
  });

  const onRemoveButtonClickHandler = (customerId) => {
    setDeleteModal((prevState) => ({
      customerId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ customerId: null, isOpen: false });
  };

  const onDisableCustomerHandler = async () => {
    try {
      setDisableLoading(true);
      // Disable the customer and modify their subscription (make them a normal customer)
      const disableCustomer = firebase
        .functions()
        .httpsCallable('httpsCustomerDisable');
      await disableCustomer({
        customerId: deleteModal.customerId,
      });

      // setDeleteModal({ userId: null, isOpen: false });

      toastr.success('', 'The customer was disabled successfully.');
    } catch (err) {
      console.error('Failed to disable customer', err);

      toastr.error(
        '',
        'There was a problem disabling the customer. Please contact us for support.'
      );
    }

    setDisableLoading(false);
    setDeleteModal((prevState) => ({
      userId: null,
      isOpen: false,
    }));
  };

  const {
    data: customersData,
    error: customersError,
    loading: customersLoading,
  } = useCollection('customers', {
    where: [
      ['status', '!=', 'disabled'],
      ...(group === 'property' ? [['propertyId', '==', id]] : []),
    ],
    listen: true,
  });

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'Unit',
      accessor: 'unit',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original.status === 'enabled' ? 'green' : 'orange',
          }}
        >
          {row.original.status === 'enabled'
            ? 'Active'
            : row.original.status.charAt(0).toUpperCase() +
              row.original.status.slice(1)}
        </span>
      ),
    },
    ...(group === 'admin'
      ? [
          {
            Header: 'Property Id',
            accessor: 'propertyId',
          },
        ]
      : []),
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          <div className="buttons is-right">
            <Link
              to={`/customers/${row.original.id}`}
              className="button is-small is-primary"
            >
              <span className="icon is-small">
                <i className="mdi mdi-account-edit" />
              </span>
            </Link>

            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-cancel" />
              </span>
            </button>
          </div>
        </>
      ),
      disableSortBy: true,
    },
  ];

  // Update the table when the data or search changes
  useEffect(() => {
    if (!customersData) return;

    setData(() => {
      let newData = [...customersData];

      // Filter the results based on the search
      if (search) {
        newData = [
          ...newData.filter((el) => {
            return Object.values(el).some((value) => {
              if (typeof value === 'string')
                return value.toLowerCase().includes(search.toLowerCase());
              return false;
            });
          }),
        ];
      }
      console.log('Final customer data', newData);
      return newData;
    });
  }, [search, customersData]);

  useEffect(() => {
    if (error || customersError)
      console.error('Error with customer data', error ?? customersError);
  }, [customersError, error]);

  if (error || customersError)
    return <div className="empty-message">Error, please try again later</div>;

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={disableLoading}
          confirmButtonMessage="Disable"
          title="Are you sure?"
          body="Disabling the customer will remove them from the list of active customers that you are billed for. The customer will also be informed that you will no longer be paying for their FreeCast membership."
          cancelButtonMessage="Cancel"
          onConfirmation={onDisableCustomerHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-small">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Customers</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  style={{ marginRight: 16 }}
                  to={paths.ADD_CUSTOMERS}
                  className="button"
                >
                  Add Customer
                </Link>
                <Link to={paths.IMPORT_CUSTOMERS} className="button">
                  Bulk Import Customers
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>Search:</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading || customersLoading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data}
                resultCount={data?.length || 0}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Customers;
