import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import { fetchUsers, deleteUser, usersCleanUp } from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Users.module.scss';

const Users = () => {
  const { usersData, group, error, loading, deleted } = useSelector(
    (state) => ({
      usersData: state.users.data,
      group: state.auth.userData.group,
      error: state.users.error,
      loading: state.users.loading,
      deleted: state.users.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [data, setData] = useState(usersData);
  const [search, setSearch] = useState('');
  const [groupFilter, setGroupFilter] = useState('none');

  useEffect(() => {
    if (group === 'admin' || group === 'reseller') {
      console.log('Start fetching the users:', fetchUsers());
      dispatch(fetchUsers());
    }

    return () => dispatch(usersCleanUp());
  }, [dispatch, group]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: false,
      }));
    }
  }, [deleted, loading]);

  const redirect = !group && <Redirect to={paths.ROOT} />;

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    dispatch(deleteUser(deleteModal.userId));
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('Users.group'),
      accessor: 'group',
    },
    {
      Header: useFormatMessage('Users.created'),
      accessor: 'created',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          <div className="buttons is-right">
            <Link
              to={`/users/${row.original.id}`}
              className="button is-small is-primary"
            >
              <span className="icon is-small">
                <i className="mdi mdi-account-edit" />
              </span>
            </Link>

            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          </div>
        </>
      ),
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    if (!usersData) return;

    setData(() => {
      let newData = [...usersData];

      // Filter out any users that don't have the same group as the filter
      if (groupFilter && groupFilter !== 'none') {
        newData = [...newData.filter((user) => user.group === groupFilter)];
      }

      // Filter out based on our search
      if (search) {
        newData = [
          ...newData.filter((el) => {
            return Object.values(el).some((value) => {
              if (typeof value === 'string')
                return value.toLowerCase().includes(search.toLowerCase());
              return false;
            });
          }),
        ];
      }

      return newData;
    });
  }, [search, groupFilter, usersData]);

  const deleteMessage = useFormatMessage('Users.delete');
  const permDeleteMessage = useFormatMessage('Users.permDelete');
  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {redirect}
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title="Are you sure?"
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          dangerousAction
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-small">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Users.users')}</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link to={paths.ADD_USER} className="button">
                  Create User
                </Link>
              </div>
              {/* <div className="level-item">
                <Link to={paths.IMPORT_USERS} className="button">
                  Bulk Import Properties
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
            <span
              className={classNames('card-header-title', classes.tableHeader)}
            >
              <span>{useFormatMessage('Users.group')}</span>
              <div style={{ marginLeft: '16px' }} className="select">
                <select onChange={(e) => setGroupFilter(e.target.value)}>
                  <option value="none">None</option>
                  <option value="admin">Admin</option>
                  <option value="distributor">Distributor</option>
                  <option value="reseller">Reseller</option>
                  <option value="property">Property</option>
                </select>
              </div>
            </span>
          </header>
          <div className="b-table">
            {loading ? (
              <ClipLoader />
            ) : (
              <Table
                columns={columns}
                data={data}
                resultCount={data?.length || 0}
              />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
